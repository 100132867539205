@color_1: #333333;
@color_2: #a10701;
@color_3: #000000;
@color_4: #FFFFFF;
@color_5: #fbf949;
@color_6: #505d65;
@color_7: #aaaaaa;
@color_8: #565656;
@color_9: #800;
@color_10: #F00;
@color_11: #777;
@font_family_1: MS Sans Serif, Tahoma, Verdana, Arial, Helvetica;
@font_family_2: monospace;
@font_family_3: Tahoma, Arial, Helvetica, sans-serif;
@background_color_1: #EDEDED;
@background_color_2: #FFFFFF;
@background_color_3: #F3F4F5;
@background_color_4: #ccc;
@background_color_5: #505d65;
@background_color_6: #65707f;
@background_color_7: #e0e0e0;
@background_color_8: #DDDDDD;
@background_color_9: #F1F1F1;
@background_color_10: #cccccc;
@background_color_11: #4767A8;
@background_color_12: #FFCC99;
@background_color_13: #ffb;






body {
    background-color: @background_color_1;
    font: normal 11px "MS Sans Serif", Tahoma, Verdana, Arial, Helvetica;
    color: @color_1;
    margin: 0;
    padding: 0;
    background: #eeeeee;
}
td {
    font-family: @font_family_1;
    font-size: 8pt;
}
.b {
    font-weight: bold;
}
.big {
    font-size: 150%;
}
textarea {
    font-family: @font_family_2;
    font-size: 9pt;
    width: 100%;
    overflow: auto;
}
input.x1 {
    width: 500px;
}
textarea.x1 {
    width: 500px;
}
a {
    color: @color_2;
    text-decoration: underline;
    font-size: 11px;
    &:hover {
        color: @color_2;
        text-decoration: none;
    }
}
a.add {
    background-image: url(/publicclxmobilenet/admin/img/add.gif);
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 15px;
}
a.edit {
    background-image: url(/publicclxmobilenet/admin/img/edit.gif);
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 15px;
}
a.delete {
    background-image: url(/publicclxmobilenet/admin/img/delete.gif);
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 15px;
}
a.page {
    background-image: url(/publicclxmobilenet/admin/img/toright.gif);
    background-position: 2px left;
    background-repeat: no-repeat;
    padding-left: 15px;
}
a.scope {
    background-image: url(/publicclxmobilenet/admin/img/toright.gif);
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 15px;
}
a.icon {
    background-image: url(/publicclxmobilenet/admin/img/toright.gif);
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 15px;
}
tr.list1 {
    td {
        padding: 5px;
    }
    background-color: @background_color_2;
}
tr.list2 {
    td {
        padding: 5px;
    }
    background-color: @background_color_3;
}
tr.th {
    td {
        padding: 5px;
    }
    background-color: @background_color_4;
    font-weight: bold;
}
h3 {
    display: inline;
    font-size: 14px;
    color: @color_1;
}
h2 {
    display: inline;
    font-size: 14px;
    color: @color_3;
}
div.headline {
    height: 20px;
    border-bottom: 1px dashed #aaaaaa;
    margin-bottom: 20px;
}
#headline {
    height: 20px;
    border-bottom: 1px dashed #aaaaaa;
    margin-bottom: 20px;
}
#header {
    height: 100px;
    background-color: @background_color_2;
    position: relative;
    z-index: 15;
    img {
        margin: 10px 10px 0 10px;
        max-height: 55px;
    }
    ul.rnav {
        float: right;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            float: left;
            height: 23px;
            font: normal 12px Arial, Helvetica;
            padding: 5px 5px 0 0;
            margin: 0 5px 0 0;
            a {
                color: @color_1;
                text-decoration: none;
                font-size: 11px;
                &:hover {
                    color: @color_7;
                    text-decoration: none;
                }
            }
        }
    }
}
#admin_topnav {
    height: 23px;
    width: auto;
    position: absolute;
    bottom: 0px;
    left: 250px;
}
ul.topnav {
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 30;
    li {
        float: left;
        font: normal 12px Arial, Helvetica;
        padding: 3px 20px 3px 0;
        margin: 0 5px 0 0;
        background-color: @background_color_5;
        border-top: 1px solid #a8a8a8;
        border-left: 1px solid #a8a8a8;
        border-right: 1px solid #a8a8a8;
        color: @color_4;
        display: block;
        z-index: 3;
        height: 15px;
        border-radius: 5px 5px 0px 0px;
        a {
            padding-left: 20px;
            color: @color_4;
            text-decoration: none;
            &:hover {
                padding-left: 20px;
                color: @color_5;
                text-decoration: none;
            }
        }
    }
}
div.nav {
    position: absolute;
    z-index: 3;
    top: 100px;
    width: 150px;
    background-color: @background_color_6;
    border-top: 1px solid #787878;
    border-left: 1px solid #a8a8a8;
    border-right: 1px solid #585858;
    border-bottom: 1px solid #585858;
    display: none;
    color: @color_4;
    ul {
        height: 100%;
        list-style: none;
        padding: 5px 0 5px 0;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        li {
            height: 20px;
            padding: 0;
            margin: 0;
            font: normal 12px Arial, Helvetica;
            a {
                cursor: hand;
                display: block;
                padding: 3px 0 3px 20px;
                margin: 0;
                width: auto;
                width: 100%;
                &:link {
                    color: @color_4;
                    text-decoration: none;
                }
                &:visited {
                    color: @color_4;
                    text-decoration: none;
                }
                &:hover {
                    background-color: @background_color_7;
                    color: @color_6;
                    text-decoration: none;
                }
            }
        }
    }
}
#header-strip {
    background: url('/img/header-strip.gif');
    height: 32px;
}
#leftwrap {
    margin: -16px 0 0 0;
    float: left;
    width: 70%;
    height: 100%;
    background: #F3F5F6;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    position: relative;
    #strip {
        height: 33px;
        background: #333;
    }
    #content {
        padding: 10px;
        position: relative;
        height: 100%;
    }
}
#subnav {
    margin: 0 10px 0 70%;
    background: #eeeeee;
    position: relative;
    padding: 45px 10px 10px 10px;
}
p.button-panel {
    padding: 10px 5px;
    background-color: @background_color_8;
    display: block;
    margin: 10px 0;
    input {
        vertical-align: middle;
    }
    a {
        font-size: 13px;
    }
}
div.form-panel {
    padding: 4px;
    background-color: @background_color_9;
}
table {
    tr {
        td {
            text-align: left;
        }
    }
}
.formtitle {
    color: @color_8;
    font-size: 10pt;
}
td.title {
    background-color: @background_color_10;
    color: @color_1;
    font-family: @font_family_3;
    font-style: normal;
    font-size: 10px;
    font-weight: bold;
}
td.subtitle {
    background-color: @background_color_11;
    color: @color_4;
    font-family: @font_family_3;
    font-style: normal;
    font-size: 10px;
    font-weight: bold;
}
select {
    color: @color_3;
    font-family: @font_family_3;
    font-style: normal;
    font-size: 11px;
}
.selected {
    background-color: @background_color_12;
    color: @color_3;
    font-style: normal;
}
div.autocomplete {
    width: 100px;
    background: #fff;
    display: inline;
    ul {
        border: 1px solid #888;
        margin: 0;
        padding: 0;
        width: 100%;
        list-style-type: none;
        li {
            margin: 0;
            padding: 3px;
        }
        li.selected {
            background-color: @background_color_13;
            font-size: 11px;
        }
        strong.highlight {
            color: @color_9;
            margin: 0;
            padding: 0;
        }
    }
}
.clx_staging_info {
    height: 10px;
    border: 2px solid #F00;
    background: #FEE none repeat scroll 0% 0%;
    padding: 10px;
    color: @color_10;
    text-align: center;
    top: 67px;
    left: 16px;
}
.hidden {
    display: none;
}
.clx_subnav_holder {
    position: relative;
    >ul {
        position: absolute;
        padding: 10px;
        left: -1px;
        top: 21px;
        width: auto;
        border: 1px solid #a8a8a8;
        background: #505d65;
        border-radius: 0px 0px 5px 5px;
        min-width: 155px;
        li {
            border: none;
            width: auto;
            padding: 0px;
            margin: 0px;
            width: 100%;
            height: 20px;
            white-space: nowrap;
            a {
                padding: 0px;
                &:hover {
                    padding: 0px;
                }
            }
        }
    }
    &:hover {
        >.clx_subnav {
            display: block;
        }
    }
}
.imgtextbox {
    width: 50%;
    border: 1px solid #dddddd;
    float: left;
    position: relative;
    height: 100%;
}
.imgbox {
    margin: 0 0 0 51%;
    padding: 5px;
    border: 1px solid #dddddd;
    height: 99%;
    position: relative;
    padding: 10px;
}
body.image {
    .imgbox {
        img {
            max-width: 250px;
        }
    }
}
hr {
    border: 0px;
    border-bottom: 1px dashed #aaaaaa;
}
.info_message {
    background: url('/publicclxmobilenet/admin/img/info.gif') scroll no-repeat #fff 10px 10px;
    padding: 10px 50px 10px;
    width: 200px;
    border: 1px dashed #aaaaaa;
}
.chooseArticle {
    padding: 15px;
    h3 {
        margin-bottom: 15px;
    }
}
a#chooseArticleLink {
    cursor: pointer;
}
ul.folderlist {
    margin: 0;
    padding: 0;
    li {
        background: rgba(0, 0, 0, 0) url("/publicclxmobilenet/admin/img/folder.gif") no-repeat scroll 0 6px;
        line-height: 25px;
        list-style: outside none none;
        margin: 0;
        padding-left: 23px;
        display: block;
    }
}
ul.filelist {
    margin: 0;
    padding: 0;
    li {
        background: rgba(0, 0, 0, 0) url("/publicclxmobilenet/admin/img/folder.gif") no-repeat scroll 0 6px;
        line-height: 25px;
        list-style: outside none none;
        margin: 0;
        padding-left: 23px;
        display: block;
        background: rgba(0, 0, 0, 0) url("/publicclxmobilenet/admin/img/file.gif") no-repeat scroll 0 4px;
    }
}
a.folder {
    background: rgba(0, 0, 0, 0) url("/publicclxmobilenet/admin/img/folder.gif") no-repeat scroll 0 6px;
    line-height: 25px;
    list-style: outside none none;
    margin: 0;
    padding-left: 23px;
    display: block;
}
p.notice {
    padding: 10px;
    line-height: 18px;
    border-radius: 5px;
    position: relative;
    min-height: 33px;
    border: 1px solid #777;
    background: #ddffd4;
    &:after {
        display: block;
        position: absolute;
        top: 17px;
        right: 10px;
        color: @color_11;
        font-size: 40px;
        content: '\2714';
    }
}
p.warning {
    padding: 10px;
    line-height: 18px;
    border-radius: 5px;
    position: relative;
    min-height: 33px;
    border: 1px solid #777;
    background: #feffd4;
    &:after {
        display: block;
        position: absolute;
        top: 17px;
        right: 10px;
        color: @color_11;
        font-size: 40px;
        content: '\26a0';
    }
}
p.error {
    padding: 10px;
    line-height: 18px;
    border-radius: 5px;
    position: relative;
    min-height: 33px;
    border: 1px solid #777;
    background: #ffd4d4;
    &:after {
        display: block;
        position: absolute;
        top: 17px;
        right: 10px;
        color: @color_11;
        font-size: 40px;
        content: '\2716';
    }
}
.float_right {
    float: right;
}
